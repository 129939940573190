.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in-button {
    opacity: 0;
    animation: 0.4s ease-in 4s forwards fadeIn;
    padding-top: 40px;
    height: 25px;
}

.fade-in-first-clue {
    opacity: 0;
    animation: 0.4s ease-in 12s forwards fadeIn;
    padding-top: 20px;
}

.fade-in-button-one {
    opacity: 0;
    animation: 0.4s ease-in 12s forwards fadeIn;
    padding-top: 20px;
}

.fade-in-button-two {
    opacity: 0;
    animation: 0.4s ease-in 12.35s forwards fadeIn;
    padding-top: 20px;
}

.App-header {
    min-height: 100vh;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}